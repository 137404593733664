import React, { useContext, Fragment } from 'react'
import { observer } from 'mobx-react'
import { Consent } from 'components/consent/consent'
import { Error } from 'components/error/error'
import { StoreContext } from 'components/App'
import { Success } from 'components/success/success'
import { SelectMethod } from 'components/selectMethod/SelectMethod'
import { ProvidePhone } from './providePhone/ProvidePhone'
import { WaitingForAuth } from './waitingForAuth/WaitingForAuth'
import { DemoPaymentSuccess } from './demoPaymentSuccess/DemoPaymentSuccess'

export const Content: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const { currentScreen } = store.AppState
  const { page } = currentScreen

  return (
    <Fragment>
      {page === 'consent' && <Consent />}
      {page === 'error' && <Error />}
      {page === 'success' && <Success />}
      {page === 'select-method' && <SelectMethod />}
      {page === 'provide-phone' && <ProvidePhone />}
      {page === 'waiting-for-auth' && <WaitingForAuth />}
      {page === 'demo-payment-success' && <DemoPaymentSuccess />}
    </Fragment>
  )
})
