import React, { useState } from 'react'
import { observer } from 'mobx-react'

import './styles.scss'
import { Brandings } from '../../components/brandings'

export const DemoPaymentSuccess = observer(() => {
  const url = new URL(window.location.href)
  //   check query param branding
  const branding = url.searchParams.get('branding')

  return (
    <div className='page-wrap'>
      <div className='demo-payment-success-page'>
        <div className='checkmark'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='80'
            height='80'
            viewBox='0 0 80 80'
            fill='none'
          >
            <path
              d='M50.794 34.1419C51.2406 33.7034 51.247 32.9858 50.8084 32.5392C50.3698 32.0927 49.6523 32.0862 49.2057 32.5248L36.4313 45.0711L31.4679 40.1077C31.0253 39.6651 30.3077 39.6651 29.8652 40.1077C29.4226 40.5503 29.4226 41.2679 29.8651 41.7105L36.4169 48.2623L50.794 34.1419Z'
              fill='#2A0062'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M72 40C72 57.6731 57.6731 72 40 72C22.3269 72 8 57.6731 8 40C8 22.3269 22.3269 8 40 8C57.6731 8 72 22.3269 72 40ZM69.7333 40C69.7333 56.4213 56.4213 69.7333 40 69.7333C23.5787 69.7333 10.2667 56.4213 10.2667 40C10.2667 23.5787 23.5787 10.2667 40 10.2667C56.4213 10.2667 69.7333 23.5787 69.7333 40Z'
              fill='#2A0062'
            />
          </svg>
        </div>

        <div className='spacer' />

        <h1>Success!</h1>

        <h2>Payment sent successfully</h2>

        <div className='powered-by'>
          Powered by:
          <Brandings />
        </div>
      </div>
    </div>
  )
})
