import React, { createContext, useContext, useEffect } from 'react'

import { getQueryVariable } from 'methods/getQueryVariable'
import './styles.scss'
import { rootStore } from 'stores/Root.store'
import { Content } from 'components/content'
import { DeclineModal } from 'components/modal/decline.modal'
import { SettingsPage } from './SettingsPage/SettingsPage'

export const StoreContext = createContext(rootStore)

export const App: React.FC = () => {
  const path = window.location.pathname

  const store = useContext(StoreContext)

  const [loading, setLoading] = React.useState(true)

  function changeTheme(themeId: string) {
    if (themeId === 'signicat') {
      store.AppState.setThemeId('sample-shop-demo-theme')
    }

    if (themeId === 'visa') {
      store.AppState.setThemeId('bootz')
    }

    if (themeId === 'sparTheme') {
      store.AppState.setThemeId('spar')
    }

    if (themeId === 'checkout') {
      store.AppState.setThemeId('checkout')
    }

    if (themeId === 'sample-shop-demo-theme') {
      store.AppState.setThemeId('sample-shop-demo-theme')
    }
  }

  useEffect(() => {
    if (path === '/settings') {
      setLoading(false)
      return
    }

    const sgncBrand = getQueryVariable('sgncBrand')
    sgncBrand && store.AppState.setsgncBrand(sgncBrand === 'true')

    const themeId = getQueryVariable('themeId')
    if (themeId) changeTheme(themeId)

    if (path === '/success') {
      if (themeId === 'sample-shop-demo-theme' || themeId === 'signicat') {
        store.AppState.changePage('demo-payment-success')
      } else {
        store.AppState.changePage('success')
      }
    }

    if (path === '/mobile-id') {
      store.AppState.setActivePage('provide-phone')
    }

    setLoading(false)
  }, [])

  const renderContent = () => {
    console.log('path', path)

    const scenario = loading
      ? 'loading'
      : path === '/settings'
      ? 'settings'
      : 'content'

    switch (scenario) {
      case 'loading':
        return <></>
      case 'settings':
        console.log('settings')
        return <SettingsPage />
      case 'content':
        return (
          <>
            <Content />
            <DeclineModal />
          </>
        )
    }
  }

  return (
    <StoreContext.Provider value={rootStore}>
      <div className='app'>{renderContent()}</div>
    </StoreContext.Provider>
  )
}
