import styled from 'styled-components'

export const SettingsPageContainer = styled.div`
  h1 {
    margin: 0;
    color: var(--Text-header-primary, #2b253a);
    text-align: center;

    /* Headline 4 */
    font-family: Inter;
    font-size: var(--Fonts-Size-Headline-4, 24px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Fonts-Line-height-Headline-4, 36px); /* 150% */
  }

  h2 {
    margin: 10px 0 00;
    color: var(--Text-header-primary, #2b253a);
    text-align: center;

    /* Paragraph 3 - Regular */
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-3, 24px); /* 150% */
    letter-spacing: -0.16px;
  }

  select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--Border-primary, #d9d9e3);
    border-radius: 8px;
    background-color: var(--Background-secondary, #ffffff);
    color: var(--Text-body-primary, #2b253a);
    font-family: Inter;
    font-size: var(--Fonts-Size-Paragraph-3, 16px);
    font-style: normal;
    font-weight: 400;
    line-height: var(--Fonts-Line-height-Paragraph-3, 24px);
    letter-spacing: -0.16px;
  }
`
