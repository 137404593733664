import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'

import './styles.scss'
import { Spinner } from '../../components/spinner/spinner'
import { Brandings } from '../../components/brandings'
import { StoreContext } from '../../components/App'
import { NewLoadingSpinner } from '../../components/NewLoadingSpinner/NewLoadingSpinner'
import { set } from 'ramda'

export const ProvidePhone = observer(() => {
  const store = useContext(StoreContext)
  const [loading, setLoading] = useState(false)

  const [phone, setPhone] = useState('+47')

  const [isError, setIsError] = useState(false)

  const proceed = async () => {
    setLoading(true)
    try {
      const res = await axios.post(
        `${process.env.WEB_API_URL}/payment-authorization/mobile-id`,
        {
          phoneNumber: phone.replaceAll(' ', '')
        },
        { withCredentials: true }
      )

      if (res.status === 200) {
        store.AppState.setActivePage('waiting-for-auth')
      } else {
        setIsError(true)
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      setIsError(true)
      setLoading(false)
    }

    // store.AppState.setActivePage('waiting-for-auth')
  }

  const url = new URL(window.location.href)
  //   check query param branding
  const branding = url.searchParams.get('branding')

  return (
    <div className='page-wrap'>
      {loading ? (
        <div className='block-centered'>
          <NewLoadingSpinner
            bgIframe='#fff'
            bgColor='#f7f9fc'
            primary='#5F36BC'
            width='48px'
          />
        </div>
      ) : (
        <div className='provide-phone-page'>
          <h1>Please provide your phone number</h1>

          <div className='spacer' />

          <p>
            Lorem ipsum dolor sit amet consectetur. Duis cras aliquet diam erat
            nec sit auctor. Aliquet purus viverra in neque purus et pretium
            parturient.
          </p>

          <div className='spacer' />

          <label>Phone number</label>

          <input
            className={isError ? 'error-class' : ''}
            placeholder='+47123123123'
            type='text'
            id='phone'
            value={phone}
            onChange={(e) => {
              if (!/^[0-9+]+$/.test(e.target.value.at(-1))) {
                return
              }

              if (e.target.value.at(-1) === '+' && e.target.value.length > 1) {
                return
              }

              setIsError(false)

              setPhone(e.target.value)
            }}
          />

          <div className={isError ? 'error-class visible' : 'error-class'}>
            Provided phone number is invalid
          </div>

          <div className='spacer' />

          <button type='button' onClick={proceed}>
            Continue
          </button>

          <div className='powered-by'>
            Powered by:
            <Brandings />
          </div>
        </div>
      )}
    </div>
  )
})
