import React, { FC, useEffect, useState } from 'react'
import { SettingsPageContainer } from './SettingsPage.styles'
import { Brandings } from '../../components/brandings'

const brandingOptions = [
  { value: 'signicat', label: 'Signicat' },
  { value: 'experian', label: 'Experian' },
  { value: 'equifax', label: 'Equifax' },
  { value: 'mastercard', label: 'Mastercard' }
]

export const SettingsPage: FC = () => {
  const [branding, setBranding] = useState('signicat')

  useEffect(() => {
    const restoreBranding = localStorage.getItem('branding')
    if (brandingOptions.some((option) => option.value === restoreBranding)) {
      setBranding(restoreBranding)
    }
  }, [])

  return (
    <div className='page-wrap'>
      <SettingsPageContainer>
        <h1>Settings Page</h1>
        <h2>Select branding</h2>
        {/* <Select options={[{ value: 'signicat', label: 'Signicat' }]}  > </Select> */}

        <select
          name='branding'
          id='branding'
          onChange={(e) => {
            console.log(e.target.value)
            localStorage.setItem('branding', e.target.value)
            setBranding(e.target.value)
          }}
          value={branding}
        >
          {brandingOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </SettingsPageContainer>

      <div className='powered-by'>
        Powered by:
        <Brandings />
      </div>
    </div>
  )
}
