import React, { useState } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'

import './styles.scss'
import { Spinner } from '../../components/spinner/spinner'
import { Brandings } from '../../components/brandings'
import { NewLoadingSpinner } from '../../components/NewLoadingSpinner/NewLoadingSpinner'
import { useInterval } from 'usehooks-ts'

export const WaitingForAuth = observer(() => {
  const [shouldPoll, setShouldPoll] = useState(true)

  const url = new URL(window.location.href)
  //   check query param branding
  const branding = url.searchParams.get('branding')

  const poll = async () => {
    try {
      const { data } = await axios.get<{
        result: 'COMPLETED' | 'PENDING' | 'FAILED'
      }>(`${process.env.WEB_API_URL}/payment-authorization/mobile-id`, {
        withCredentials: true
      })

      if (data?.result === 'COMPLETED') {
        window.location.href = '/success?themeId=signicat'
      }
    } catch (e) {
      console.log(e)
    }

    // store.AppState.setActivePage('waiting-for-auth')
  }

  useInterval(poll, shouldPoll ? 3000 : null)

  return (
    <div className='page-wrap'>
      <div className='waiting-for-auth-page'>
        <h1>Waiting for authorization on mobile device</h1>

        <div className='spacer' />

        <div className='block-centered'>
          <NewLoadingSpinner
            bgIframe='#fff'
            bgColor='#f7f9fc'
            primary='#5F36BC'
            width='48px'
          />
        </div>

        <div className='spacer' />

        <p>Please do not close this page.</p>

        <div className='powered-by'>
          Powered by:
          <Brandings />
        </div>
      </div>
    </div>
  )
})
