import * as React from 'react'
import '../../i18n/config'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'components/App'
import { observer } from 'mobx-react'

import './styles.scss'
import axios from 'axios'
import { useState } from 'react'
import { Spinner } from 'components/spinner/spinner'
import CheckoutLogo from '../../assets/checkout.svg'
import { Brandings } from '../brandings'
import { NewLoadingSpinner } from '../../components/NewLoadingSpinner/NewLoadingSpinner'

export const Consent: React.FC = observer(() => {
  const { t } = useTranslation()
  const store = React.useContext(StoreContext)
  const { payment, themeId } = store.AppState
  const [loading, setLoading] = useState(true)

  const getPaymentDetails = async (): Promise<void> => {
    try {
      const response = await axios.get(
        `${process.env.WEB_API_URL}/visa-payment-authorization/payment-details`,
        { withCredentials: true }
      )
      store.AppState.setPayment(response.data)
      setLoading(false)
    } catch (e) {
      store.AppState.setActivePage('error')
    }
  }

  const handleConsent = (): void => {
    if (themeId === 'sample-shop-demo-theme') {
      store.AppState.setActivePage('select-method')
      return
    }
    window.location.href = `${process.env.WEB_API_URL}/visa-payment-authorization/redirect-to-auth`
  }

  const handleDoNotConsent = (): void => {
    if (themeId === 'sample-shop-demo-theme') {
      return
    }
    store.AppState.setModalVisible(true)
  }

  React.useEffect(() => {
    getPaymentDetails()
  }, [])

  const url = new URL(window.location.href)
  //   check query param branding
  const branding = url.searchParams.get('branding')

  const [containerKey, setContainerKey] = useState(0)

  React.useEffect(() => {
    if (themeId === 'sample-shop-demo-theme') {
      setContainerKey(containerKey + 1)
    }
  }, [themeId])

  return (
    <div className='consent__container' key={containerKey}>
      {themeId === 'checkout' ? (
        <div className={`consent__top consent__top--${themeId}`}>
          <div className={`provider-logo provider-logo-container`}>
            <img src={CheckoutLogo} />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={`consent consent--${themeId}`}>
        {themeId === 'sample-shop-demo-theme' && (
          <div className='powered-by'>
            Powered by:
            <Brandings />
          </div>
        )}

        <div className={`consent__banner consent__banner--${themeId}`}>
          <div className={`provider-logo provider-logo--${themeId}`} />
        </div>
        <div className='consent__data'>
          {loading ? (
            <div className='block-centered'>
              {themeId === 'sample-shop-demo-theme' ? (
                <NewLoadingSpinner
                  bgIframe='#fff'
                  bgColor='#f7f9fc'
                  primary='#5F36BC'
                  width='48px'
                  height='348px'
                />
              ) : (
                <Spinner />
              )}
            </div>
          ) : (
            <div className='consent__data--content'>
              <p className='consent__data--description'>
                {t('consent.shareInfo')}
              </p>
              {themeId !== 'sample-shop-demo-theme' && (
                <p className='consent__data--amount'>
                  {payment.amount} {payment.currency}
                </p>
              )}

              {themeId === 'sample-shop-demo-theme' && (
                <div className='consent__data--value'>
                  {payment.amount} {payment.currency}
                </div>
              )}

              <ul>
                <li>
                  <b>Merchant:</b>{' '}
                  {themeId === 'checkout' ? 'checkout.com' : 'Sample Shop'}
                </li>
                <li>transactionID: {payment.id}</li>
              </ul>
            </div>
          )}
        </div>
        <div className='consent__data consent__data--not-shared'>
          {/*<p dangerouslySetInnerHTML={{ __html: t('consent.notShareInfo') }} />*/}
          {/*{handleUserDataListNotToShare()}*/}
        </div>
        {!loading && (
          <div className='consent__action'>
            <button
              className={`btn btn--secondary btn--secondary--${themeId}`}
              onClick={(): void => handleDoNotConsent()}
            >
              <span>{t('consent.consentDeny')}</span>
            </button>
            <button
              className={`btn btn--primary btn--primary--${themeId}`}
              onClick={(): void => handleConsent()}
            >
              <span>{t('consent.consentConfirm')}</span>
              {themeId === 'visa' ? (
                <div className='btn--primary-arrow'></div>
              ) : (
                ''
              )}
            </button>
          </div>
        )}
        <div className='consent__bottom'>
          <div className='powered-by'>
            <div className='foo-logo-sgn' />
            {themeId === 'visa' ? <div className='foo-logo-visa' /> : ''}
          </div>
        </div>
      </div>
    </div>
  )
})
