import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import axios from 'axios'

import './styles.scss'
import { Spinner } from '../../components/spinner/spinner'
import { Brandings } from '../../components/brandings'
import { StoreContext } from '../../components/App'
import { NewLoadingSpinner } from '../../components/NewLoadingSpinner/NewLoadingSpinner'

export const SelectMethod = observer(() => {
  const store = useContext(StoreContext)
  const [loading, setLoading] = useState(false)

  const proceedNbid = async () => {
    setLoading(true)

    window.location.href = `${process.env.WEB_API_URL}/payment-authorization/redirect-to-auth?method=nbid`
  }

  const proceedPhone = async () => {
    window.location.href = `${process.env.WEB_API_URL}/payment-authorization/redirect-to-auth?method=mobile-id`
  }

  const url = new URL(window.location.href)
  //   check query param branding
  const branding = url.searchParams.get('branding')

  return (
    <div className='page-wrap'>
      {loading ? (
        <div className='block-centered'>
          <NewLoadingSpinner
            bgIframe='#fff'
            bgColor='#f7f9fc'
            primary='#5F36BC'
            width='48px'
            height='326px'
          />
        </div>
      ) : (
        <div className='choose-page'>
          <h1>Choose the identity verification method</h1>

          <div className='spacer' />

          <div className='choose-page__secondary'>
            <nav>
              <button className='choose-page-item' onClick={proceedNbid}>
                <span className='choose-page-item__graphic'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                    fill='none'
                  >
                    <path
                      d='M5.68182 15.1136H13.4091C14.4394 15.1136 15.3409 14.2121 15.3409 13.1818C15.3409 12.1515 14.4394 11.25 13.4091 11.25H5.68182C4.65152 11.25 3.75 12.1515 3.75 13.1818C3.75 14.2121 4.65152 15.1136 5.68182 15.1136Z'
                      fill='#39134C'
                    />
                    <path
                      d='M5.68182 30.5681H13.4091C14.4394 30.5681 15.3409 29.6666 15.3409 28.6363C15.3409 27.606 14.4394 26.7045 13.4091 26.7045H5.68182C4.65152 26.7045 3.75 27.606 3.75 28.6363C3.75 29.6666 4.65152 30.5681 5.68182 30.5681Z'
                      fill='#39134C'
                    />
                    <path
                      d='M13.4091 38.2956H5.68182C4.65152 38.2956 3.75 37.394 3.75 36.3637C3.75 35.3334 4.65152 34.4319 5.68182 34.4319H13.4091C14.4394 34.4319 15.3409 35.3334 15.3409 36.3637C15.3409 37.394 14.4394 38.2956 13.4091 38.2956Z'
                      fill='#39134C'
                    />
                    <path
                      d='M21.1363 22.8411H28.8636C29.8939 22.8411 30.7954 21.9396 30.7954 20.9093C30.7954 19.8789 29.8939 18.9774 28.8636 18.9774H21.1363C20.106 18.9774 19.2045 19.8789 19.2045 20.9093C19.2045 21.9396 20.106 22.8411 21.1363 22.8411Z'
                      fill='#39134C'
                    />
                    <path
                      d='M28.8636 30.5681H21.1363C20.106 30.5681 19.2045 29.6666 19.2045 28.6363C19.2045 27.606 20.106 26.7045 21.1363 26.7045H28.8636C29.8939 26.7045 30.7954 27.606 30.7954 28.6363C30.7954 29.6666 29.8939 30.5681 28.8636 30.5681Z'
                      fill='#39134C'
                    />
                    <path
                      d='M36.5909 15.1136H44.3182C45.3485 15.1136 46.25 14.2121 46.25 13.1818C46.25 12.1515 45.3485 11.25 44.3182 11.25H36.5909C35.5606 11.25 34.6591 12.1515 34.6591 13.1818C34.6591 14.2121 35.5606 15.1136 36.5909 15.1136Z'
                      fill='#39134C'
                    />
                    <path
                      d='M44.3182 22.8411H36.5909C35.5606 22.8411 34.6591 21.9396 34.6591 20.9093C34.6591 19.8789 35.5606 18.9774 36.5909 18.9774H44.3182C45.3485 18.9774 46.25 19.8789 46.25 20.9093C46.25 21.9396 45.3485 22.8411 44.3182 22.8411Z'
                      fill='#39134C'
                    />
                    <path
                      d='M36.5909 38.2956H44.3182C45.3485 38.2956 46.25 37.394 46.25 36.3637C46.25 35.3334 45.3485 34.4319 44.3182 34.4319H36.5909C35.5606 34.4319 34.6591 35.3334 34.6591 36.3637C34.6591 37.394 35.5606 38.2956 36.5909 38.2956Z'
                      fill='#39134C'
                    />
                  </svg>
                </span>
                <span className='choose-page-item__title'>BankID</span>
                <span className='choose-page-item__subtitle'>Norway</span>
                <div className='choose-page-item__arrow'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <path
                      d='M10.7997 5.4504C10.5881 5.23886 10.2452 5.23886 10.0337 5.4504C9.82212 5.66193 9.82212 6.0049 10.0337 6.21643L13.2756 9.45841L5.41667 9.4584C5.11751 9.4584 4.875 9.70092 4.875 10.0001C4.875 10.2992 5.11751 10.5417 5.41667 10.5417L13.2756 10.5417L10.0337 13.7837C9.82212 13.9953 9.82212 14.3382 10.0337 14.5498C10.2452 14.7613 10.5881 14.7613 10.7997 14.5498L14.9663 10.3831C15.1779 10.1716 15.1779 9.8286 14.9663 9.61706L10.7997 5.4504Z'
                      fill='#794CDE'
                    />
                  </svg>
                </div>
              </button>

              <button className='choose-page-item' onClick={proceedPhone}>
                <span className='choose-page-item__graphic'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='50'
                    height='50'
                    viewBox='0 0 50 50'
                    fill='none'
                  >
                    <rect width='50' height='50' fill='none' />
                    <path
                      d='M25 9.16674C25.4603 9.16674 25.8334 8.79365 25.8334 8.33341C25.8334 7.87317 25.4603 7.50008 25 7.50008C24.5398 7.50008 24.1667 7.87317 24.1667 8.33341C24.1667 8.79365 24.5398 9.16674 25 9.16674Z'
                      fill='#2A0062'
                    />
                    <path
                      d='M20.9583 42.5001C20.9583 42.1089 21.2754 41.7917 21.6666 41.7917H28.3333C28.7245 41.7917 29.0416 42.1089 29.0416 42.5001C29.0416 42.8913 28.7245 43.2084 28.3333 43.2084H21.6666C21.2754 43.2084 20.9583 42.8913 20.9583 42.5001Z'
                      fill='#2A0062'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M25.1491 16.6667C22.8143 16.6667 20.9216 18.5595 20.9216 20.8942C20.9216 23.229 22.8143 25.1217 25.1491 25.1217C27.4839 25.1217 29.3766 23.229 29.3766 20.8942C29.3766 18.5595 27.4839 16.6667 25.1491 16.6667ZM22.3383 20.8942C22.3383 19.3419 23.5967 18.0834 25.1491 18.0834C26.7015 18.0834 27.9599 19.3419 27.9599 20.8942C27.9599 22.4466 26.7015 23.7051 25.1491 23.7051C23.5967 23.7051 22.3383 22.4466 22.3383 20.8942Z'
                      fill='#2A0062'
                    />
                    <path
                      d='M18.9167 33.1182C18.9167 30.069 21.6447 27.4899 25.1484 27.4899C28.6522 27.4899 31.3802 30.069 31.3802 33.1182C31.3802 33.5094 31.6973 33.8265 32.0885 33.8265C32.4797 33.8265 32.7969 33.5094 32.7969 33.1182C32.7969 29.1681 29.3105 26.0732 25.1484 26.0732C20.9864 26.0732 17.5 29.1681 17.5 33.1182C17.5 33.5094 17.8172 33.8265 18.2084 33.8265C18.5996 33.8265 18.9167 33.5094 18.9167 33.1182Z'
                      fill='#2A0062'
                    />
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.6249 42.5001C12.6249 44.272 14.0614 45.7084 15.8333 45.7084H34.1666C35.9385 45.7084 37.3749 44.272 37.3749 42.5001L37.3749 7.50008C37.3749 5.72817 35.9385 4.29175 34.1666 4.29175H15.8333C14.0614 4.29175 12.6249 5.72817 12.6249 7.50008V42.5001ZM15.8333 44.2917C14.8438 44.2917 14.0416 43.4896 14.0416 42.5001L14.0416 7.50008C14.0416 6.51057 14.8438 5.70842 15.8333 5.70842L34.1666 5.70841C35.1561 5.70841 35.9583 6.51057 35.9583 7.50008L35.9583 42.5001C35.9583 43.4896 35.1561 44.2917 34.1666 44.2917H15.8333Z'
                      fill='#2A0062'
                    />
                  </svg>
                </span>
                <span className='choose-page-item__title'>MobileID</span>
                <span className='choose-page-item__subtitle'>
                  Use your MobileID to authenticate
                </span>
                <div className='choose-page-item__arrow'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <path
                      d='M10.7997 5.4504C10.5881 5.23886 10.2452 5.23886 10.0337 5.4504C9.82212 5.66193 9.82212 6.0049 10.0337 6.21643L13.2756 9.45841L5.41667 9.4584C5.11751 9.4584 4.875 9.70092 4.875 10.0001C4.875 10.2992 5.11751 10.5417 5.41667 10.5417L13.2756 10.5417L10.0337 13.7837C9.82212 13.9953 9.82212 14.3382 10.0337 14.5498C10.2452 14.7613 10.5881 14.7613 10.7997 14.5498L14.9663 10.3831C15.1779 10.1716 15.1779 9.8286 14.9663 9.61706L10.7997 5.4504Z'
                      fill='#794CDE'
                    />
                  </svg>
                </div>
              </button>
            </nav>
          </div>

          <div className='powered-by'>
            Powered by:
            <Brandings />
          </div>
        </div>
      )}
    </div>
  )
})
