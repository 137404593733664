import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  100% {
    transform: rotate(1turn);
  }

`

interface ContainerProps {
  width?: string
  height?: string
  bgColor?: string
  primary?: string
  thickness?: number
  bgIframe?: string
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 0;
  width: ${(props) => (props.width ? props.width : '50px')};
  height: ${(props) => (props.width ? props.width : '50px')};
  display: block;
  box-sizing: border-box;
  border-radius: 50px;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: ${(props) => (props.bgColor ? props.bgColor : '#399953')};
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(
        ${(props) => (props.primary ? props.primary : '#399953')},
        ${(props) => (props.primary ? props.primary : '#399953')}
      ),
      linear-gradient(
        ${(props) => (props.bgColor ? props.bgColor : '#ffffff')},
        ${(props) => (props.bgColor ? props.bgColor : '#ffffff')}
      ),
      linear-gradient(
        ${(props) => (props.bgColor ? props.bgColor : '#ffffff')},
        ${(props) => (props.bgColor ? props.bgColor : '#ffffff')}
      );
    animation: ${rotate} 0.6s cubic-bezier(0.67, 0.34, 0.3, 0.71) infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: ${(props) => (props.thickness ? props.thickness + 'px' : '6px')};
    top: ${(props) => (props.thickness ? props.thickness + 'px' : '6px')};
    width: calc(
      100% -
        ${(props) => (props.thickness ? props.thickness * 2 + 'px' : '12px')}
    );
    height: calc(
      100% -
        ${(props) => (props.thickness ? props.thickness * 2 + 'px' : '12px')}
    );
    background: ${(props) =>
      props.bgIframe
        ? props.bgIframe
        : props.primary
        ? props.bgColor
        : '#ffffff'};
    border-radius: 50px;
  }
`

export const Wrap = styled.div<{ padding: string; height: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '0')};
  height: ${(props) => (props.height ? props.height : 'auto')};
`
